.header {
    width: 100%;
	position: absolute;
	z-index: 10;
	padding: 24px 30px;
	transition: all .3s ease-in-out;
	display: flex;
	align-items: center;
	
	.logo {
		overflow: hidden;
		margin: 0 10px 0 30px;

		@include breakpoint(phablet) {
			margin: 0 0 0 22px;

		}
	}
 
	.logo-enarq {
		width: 100%;
		height: 22px;
		
		path {
			transition: fill .6s ease .1s;
		}

		.open-menu & path {
			fill: $black;
		}		
	}

	&__toggle {
	    width: 27px;
	}

	&__toggle-line {
	    display: block;
	    background: $white;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.fixed {
		top: 0;
		position: fixed;
		z-index: 11;
		animation: headerFix .5s ease-out;	
		background-color: $black;
	}

	&.is-active { 
	    position: fixed;
	    z-index: 11;
		background: transparent;

		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    background: $black;
			}
			&:nth-child(2) {
			    background: $black;
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    background: $black;
			}
		}		
	}		

	@include breakpoint(phablet) {

		.logo-enarq {
			height: 36px;
		}		
	}
	@include breakpoint(tablet) {
		padding: 6vh 10vh 0;

		&__toggle {
			display: none;
		}
		.logo {
			margin: 0;
		}		
		.logo-enarq {
			width: 220px;

			.open-menu & path {
				fill: $white;
			}		
		}	
		&.fixed {
			top: 0;
			position: absolute;
			background-color: transparent;
		}		
		&.is-active { 
			// position: fixed;
			z-index: 1;		
		}
	}
	@include breakpoint(laptop) {
		
		// .content {
			
		// 	.header__toggle {
		// 		margin-top: 8vh;
		// 	}	
		// }
		
		// .logo-enarq {
		// 	// height: 18vh;
		// }		
	}		
}

.menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 80px 30px 50px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
    background: rgba(255, 255, 255, .95);
    transition: opacity 400ms ease, left 100ms ease 600ms;

	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    // overflow: scroll;
	}
	
	.menu-inner {
	    margin: 0;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    
			li {

				a {
				    font-size: 28px; 
				    font-weight: 600;  
				    line-height: 50px;
				    color: $black;
				    
				    &:hover {
				    	color: $black;
				    }
				}

			}
		}

		.menu-social {
			display: flex;
			width: 100px;
			justify-content: space-between;			
			margin-top: 6vh;
			
			.icon {
				width: 32px;
				height: 32px;
				fill: $black;
				transition: fill .6s ease;
				
				&:hover {
					fill: $black;					
				}
			}
		}
	}

	// .gracias & { display: none;}

	@include breakpoint(mobilelandscape) {
		
		.menu-inner {
			display: grid;
			grid-template-columns: 40% 60%;
			
			.menu-list {
				grid-row: 1 / 3;
				align-self: end;
				
				li {

					a {
					    font-size: 26px; 
					    line-height: 38px;
					}
				}				
			}
			.menu-social {
				grid-column: 2 / 3;
				grid-row: 2 / 3;
				align-self: end;
			}
		}
	}
	@include breakpoint(tablet) {
		background: none;
		padding: 0px 10vh 0 0;
		top: 6vh;
		bottom: auto;
		left: 0;
		right: 10vh;
	    opacity: 1;	
		position: absolute;
		height: 36px;
		pointer-events: none;
		
		.menu-inner {
			display: flex;
			justify-content: flex-end;

			.menu-list {
				display: flex;
				margin-right: 15px;
				
				li {
					margin: 0 12px;

					a {
						font-size: 16px;
						font-weight: 400;
						line-height: 32px;
						color: $white;
						pointer-events: auto;
					}
				}				
			}

			.menu-social {
				margin: 0;
				align-items: center;
				width: 70px;

				a {
					pointer-events: auto;
				}
				
				.icon {
					width: 26px;
					height: 26px;
					fill: $white;

					&:hover {
						fill: #FFCD00;
					}
				}
			}			
		}			
		
		&.fixed {
		    height: auto;
			top: 0;
			padding: 20px; 
			background: $black url('../img/logo-footer.svg') no-repeat 20px center; 
			background-size: auto 32px;
			position: fixed;
			animation: headerFix .5s ease-out;
		}
	}
	@include breakpoint(laptop) {	

		.menu-inner {
			
			.menu-list {
				
				li {
					a {
					    &:hover {
							color: $over;
						}
					}
				}
			}			
			
			.menu-legal {
				margin: 6vh 0;
			}
		}
	}
}

@keyframes headerFix {
	0%   { top: -100px; }
	100% { top: 0; }
}