.hero {
	background: url('../img/hero-m.jpg') no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	position: relative;
    padding: 80px 30px;

	h1 {
		font-size: 32px;
		letter-spacing: .8px;
		color: $white;
		margin-bottom: 26px;
		text-shadow: 0px 0 18px rgba(0,0,0, .4);
		animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

		span {
			font-weight: 400;
		}
	}	

	// &:before {
	// 	content: '';
	// 	width: 100%;
	// 	height: 100%;
	// 	background: linear-gradient( to bottom, rgba(0,0,0, .4), rgba(0,0,0,0), rgba(0,0,0, .4));
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// }

	.button { 
		border-color: $white; 
		width: 180px;
	}
	
	// .gracias & {
	// 	background-image: 
	// 		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
	// 		url('../img/hero-turismo-m.jpg');
	// 	text-align: center;		
	// }	

	@include breakpoint(phablet) {
		
		h1 {
			font-size: 42px;

			span {
				display: block;
			}
		}
		
		// .gracias & {
		// 	background-image: 
		// 		linear-gradient( to bottom, rgba(0,0,0, .8), rgba(0,0,0,0), rgba(0,0,0, .8)),
		// 		url('../img/hero-turismo-m.jpg');
		// 	text-align: center;		

		// 	h1 {
		// 		font-size: 42px;
		// 	}
		// }		
	}
	@include breakpoint(tablet) {
		background-image: url('../img/hero.jpg');
		padding: 0 10vh 10vh;
		justify-content: flex-end;

		.reservation_form {
			width: 840px;
			padding: 0 0 60px;
			margin: 0 auto;
			
			.booking-mask {
				
				.date, .hb-form-field-input {
					flex: 25% 1 0;
				}
		
				.send-button {
					flex: initial;
					margin: 0;
					padding-left: 10px;
				}
			}		
		}

		.button {
			margin: 0;
		}			
	}
	@include breakpoint(laptop) {
		
		// .reservation_form {
	
		// 	.booking-mask {
		// 		max-width: 1280px;
		// 		margin: auto;
				
		// 		.date {
					
		// 			&.in, &.out {
		// 				// flex-grow: 0;
		// 			}
		// 		}			
		// 	}
		// }
	}
}


/* ----------------------------------------------
 * Generated by Animista on 2021-6-17 11:30:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}