.footer {
	background: $black;
	padding: 40px 30px 30px;
	
	.logo-footer {
		margin: 0 auto 32px;
		width: 220px;
	}

	.data {
		
		li {
			line-height: 22px;
			margin-bottom: 8px;

			a {
				color: $white;

				&:hover {
					color: $over;
				}
			}

			.ig {
				display: block;
				background: url('../img/bg-ig.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.tel {
				display: block;
				background: url('../img/bg-tel.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.mail {
				display: block;
				background: url('../img/bg-mail.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
			.map {
				display: block;
				background: url('../img/bg-map.svg') no-repeat;
				background-size: 22px;
				padding-left: 32px;
			}
		}
	}

	&_burakko {
		text-align: center;
		color: $white;
		display: block;
		padding: 16px 0;
		margin: 0;
		transition: color .3s ease;
		
		a:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(tablet) {
		padding: 10vh 0;

		.data {
			display: flex;
			justify-content: space-between;
			max-width: 960px;
			margin: 0 auto;
		}
		.logo-footer {
			margin-bottom: 48px;
		}
	} 
}