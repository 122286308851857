*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}  
sup {
	font-size: .8em;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $black;
	line-height: 1.2;
	position: relative;
	counter-reset: service;
}
a {
	text-decoration: none;
	transition: all .4s ease-out;
	color: $black;
}
p {
    margin-bottom: 12px;
}
strong {
	font-weight: 700;
}
input, textarea {
	padding: 0 8px 8px;
	width: 100%;	
	background: none;
	margin-bottom: 8px;
	border: none;
	border-bottom: 1px $black solid;
	color: $black;
	font-family: $font-base;
	min-height: 48px;
	transition: all .2s ease-in-out;
	
	&::placeholder {  
		color: $grey;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		
		&::placeholder {
			color: $white;
		} 
	}
	&.error {
		border-color: red;
	}
}
// select {
// 	appearance: none;
// 	padding: 14px 42px 14px 14px;

// 	@include breakpoint(tablet) {
// 		padding: 0 42px 0 14px;
// 	}
// }
a.button { 
	display: block;
	font-size: 14px;
	text-align: center;
	overflow: hidden;
	padding: 0;
	width: 200px;
	height: 46px;
	border: 2px $black solid; 
	border-radius: 30px;  
	background: $white;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

	&:before, & > span {
		padding: 14px;
		font-weight: 700;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	    color: $black;
		font-weight: 700;
	}

	&:before {
		color: $white;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		transform: translate3d(-100%, 0, 0);	    

	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

input.button { 
	color: $black;
	font-size: 14px;
	padding: 0;
	width: 200px;
	border: 2px $black solid; 
	border-radius: 30px;  
	background: $white;
	text-align: center;

	&:hover {
		background: $black;
		color: $white;
	}
}

.grecaptcha-badge {
	opacity: 0;
}

main > section {
	padding: 40px 30px 30px;

	> h2 {
		font-size: 32px;
	}

	@include breakpoint(tablet) {
		padding: 10vh;
	}
}

iframe {
	display: block;
}

.whatsapp-button {
	width: 48px;
	height: 48px;
	position: fixed;
	bottom: 30px;
	left: 30px;
	z-index: 30;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in-out;

	.icon {
		transition: all 400ms ease;
		transform: scale(1);
	}

    &:hover .icon {
    	transform: scale(1.1);
    }		

	&.show {
		opacity: 1;
		visibility: visible;
	}

    @include breakpoint(tablet) {
		width: 52px;
		height: 52px;
		bottom: 42px;
		left: 42px;

		.icon {
			filter: drop-shadow(2px 2px 4px rgba(0,0,0,.4));
		}
    }
}