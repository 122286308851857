.proyects {
    padding-top: 112px;

    &_grid {
        margin-top: 28px;
    }
    article {
        margin-bottom: 42px;
    }
    .picture {
        margin-bottom: 12px;
    }
    h3 {
        text-align: center;
    }

    @include breakpoint(phablet) {
        
        &_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
    }
}

.services {
    padding-top: 71px;
    
    .top {
        margin: 0 -30px 40px;
        position: relative;        
    }
    
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .8);
        position: absolute;
        top: 40px;
        left: 30px;
        z-index: 1;
    }

    .items {
        margin-top: -136px;
        position: relative;
        z-index: 1;

        li {
            background: $white;
            padding: 30px;
            border-radius: 0 0 42px 42px;
            width: 240px;
            margin: 0 auto 38px;
            box-shadow: 4px 4px 8px rgba(0,0,0,.6);
            text-align: center;
        }
    }

    .icon {
        width: 46px;
        height: 46px;
        margin: 0 auto 22px;
    }
    
    h3 {
        margin-bottom: 12px;
    }
    
    &_column {
        transition: all .8s ease-out;
        overflow: hidden;
        max-height: 0; 
        
        h3 {
            font-size: 22px;
            
            &:before {
                counter-increment: service;
                content: "0" counter(service) ". ";
                color: grey;
                font-weight: 400;
                display: block; 
            }
        }
        
        &.show {
            max-height: 2000px;
            margin-bottom: 68px; 
        } 
    }

    .button {
        margin: 0 auto;
        transition: none;

        &.hide { 
            opacity: 0;
            height: 0;
        }

        &.go-to {
            margin: 28px 0 0 0;   
            width: 120px; 
        }
    } 

    .picture {
        margin-bottom: 26px;
    }

    .detail {
        margin-bottom: 58px;
        
        &:last-child {
            margin: 0;
        }
    }
    
    @include breakpoint(phablet) {

        &_column {
            padding: 0 30px;
        }

        .detail {
            display: flex;
            justify-content: space-between;

            header {
                flex: 40%;    
                padding-right: 30px;
            }
        }

        .picture {
            order: 2;
            flex: 40%;
            margin: 0;
        }

        .items {
            display: flex;
            justify-content: space-between;
        }        
    } 
    @include breakpoint(tablet) { 

        .top {
            margin: 0 -10vh;

            .picture {
                max-height: 380px;
                overflow: hidden; 

                img {
                    object-position: bottom;
                    object-fit: cover;
                    height: 450px; 
                }
            }
        }

        h2 {
            top: 10vh;
            left: 10vh;
        } 

        .items {
            max-width: 860px;
            margin: -112px auto 68px;

            li {        
                margin: 0;
            }
        }
    }
}   

.we {
    padding-top: 72px;
    
    .top {
        margin: 0 -30px 40px;
        position: relative;        
    }
    
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .8);
        position: absolute;
        top: 40px;
        left: 30px;
        z-index: 1;
    }   

    .line {
        margin-bottom: 64px;
        font-size: 18px;
        font-style: italic;
        text-align: center;
    }

    .picture {
        margin-bottom: 48px;
    }

    h3 {
        font-size: 26px;
        color: $grey;
        margin-bottom: 22px;
    }

    h4 {
        margin: 26px 0 12px;
    }

    .ig-link {
        display: flex;
        align-items: center;
        margin-top: 36px;
    }

    @include breakpoint(phablet) {

        .line {
            font-size: 22px;
        }

        article {
            display: flex;
            align-items: center;
            
            .picture {
                order: 2;
                flex: 1 0 50%;
                margin: 0;
                max-width: 600px; 
            }
        }
        .content {
            flex: 1 0 50%;
            padding-right: 30px;
        }
    }

    @include breakpoint(tablet) {

        .top {
            margin: 0 -10vh 64px;
        }
        h2 {
            top: 10vh;
            left: 10vh;
        }          
        .content {
            padding-right: 42px;
        }
    }
}

.awards {
    padding-top: 72px;
    
    .top {
        margin: 0 -30px 40px;
        position: relative;        
    }
    
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .8);
        position: absolute;
        top: 40px;
        left: 30px;
        z-index: 1;
    }

    &_item {
        padding: 23px 0 26px 0;
        display: flex;
        position: relative;
        // cursor: pointer;

        .date {
            width: 20%;
            min-width: 70px;
            font-size: 20px;
            line-height: 18px;
            padding-top: 10px;
        }
        .separator {
            top: 10%;
            height: 80%;
            left: 20%;
            width: 1px;
            // height: 50%;
            position: absolute;
            background-color: $black;
            // top: 25%;
            // left: 12%;
        }
        .info {
            width: 80%;
            flex-direction: column;
            padding-left: 15px;
        }
    }

    @include breakpoint (phablet) {
        
        .separator {
            left: 14%;
        }
    }
    @include breakpoint (tablet) {

        .top {
            margin: 0 -10vh 64px;
        }        
        h2 {
            top: 10vh;
            left: 10vh;
        }  
        &_list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
        .info {
            padding: 0;
        }
        &_item {

            &:before {
                content: '';
                position: absolute;
                height: calc(100% - 65px);
                top: 45px;
                left: 20px;
                width: calc(100% - 20px);
                background-color: #f2f2f2;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-100%);
                transition: all 0.3s ease-in;
            }            

            &:hover:before {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
}