.contact {
	padding: 0 30px 30px;
	    
    .top {
        margin: 0 -30px 0px;
        position: relative;        
    }
    
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .8); 
        position: absolute;
        top: 40px;
        left: 30px;
        z-index: 1; 	
    }

	&-grid {
		padding-top: 72px;
	}

	.content {
		padding-top: 40px;

		p {
			font-size: 20px; 
			font-weight: 700;
			margin-bottom: 42px;
		}
	}

	#contactForm {
		margin-bottom: 42px;

		textarea {			
			padding-top: 8px;
		}
	}

	@include breakpoint(phablet) {
		
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;
		}
		#contactForm {
			margin: 0;
		}
	}
	@include breakpoint(tablet) {
		padding: 0 10vh 10vh;

        .top {
            margin: 0 -10vh 10vh;
        }        
        h2 {
            top: 10vh;
            left: 10vh;
        }  		
		&-grid {
			gap: 60px;
		}		
		.content {
			align-self: center;
		}
	}
}